import {useLocale} from '@/hooks/useLocale';
import {useStore} from '@/store';
import {zodResolver} from '@hookform/resolvers/zod';
import {i18n} from '@lingui/core';
import {Trans} from '@lingui/macro';
import {formatAmount, formatAmountWithoutCurrency} from '@zentact/common';
import {
  Button,
  ContentCard,
  InputCheckbox,
  Loading,
  SanitizeHTML,
  Typography,
  ValidationError,
} from '@zentact/ui-tailwind';
import {cn} from '@zentact/ui-tailwind/utils';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import z from 'zod';
import {trpc} from '../../../../api/trpcClient';
import {merchantRegistrationStepTitles} from '../../constants';
import {CostPlusPricingCards} from './CostPlusPricingCards';
import {FlatRatePricingCards} from './FlatRatePricingCards';

const schema = z.object({
  agree: z.custom(data => Boolean(data), 'To proceed you must agree to terms and condition'),
});

type TermsAndConditionsForm = z.infer<typeof schema>;

type Props = {
  onComplete: () => void;
  registrationSessionId: string;
};

export const TermsAndConditions = ({onComplete, registrationSessionId}: Props) => {
  const navigate = useNavigate();
  const {data: splitConfiguration, isLoading: isSplitConfigurationLoading} =
    trpc.merchantRegistration.getSplitConfiguration.useQuery(
      {
        registrationSessionId,
      },
      {
        onError: () => navigate('/error'),
      }
    );
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<TermsAndConditionsForm>({
    resolver: zodResolver(schema),
  });

  const {tenant} = useStore();
  const {locale} = useLocale();

  const signUpMutation = trpc.merchantRegistration.step2AgreeTermsAndConditions.useMutation({
    onSuccess() {
      onComplete();
    },
  });

  const {data: termsAndConditionsData} = trpc.merchantRegistration.getTermsAndConditions.useQuery(
    {},
    {keepPreviousData: true}
  );

  const onSubmit = (data: TermsAndConditionsForm) => {
    signUpMutation.mutate({
      agree: data.agree,
      registrationSessionId,
    });
  };

  if (!tenant || isSplitConfigurationLoading || !termsAndConditionsData) {
    return <Loading />;
  }

  if (!splitConfiguration) {
    console.error("Tenant doesn't have split configuration");
    return <Loading />;
  }

  return (
    <div className="flex flex-col gap-y-4">
      {!!termsAndConditionsData.termsHtml && (
        <div className="bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2">
          <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
            <Typography as="h3" variant="header-lg">
              {i18n._(merchantRegistrationStepTitles().step2)}
            </Typography>
          </div>
          <div className="px-4 py-6 text-sm text-gray-800 sm:p-8 max-h-[400px] overflow-y-auto">
            <SanitizeHTML html={termsAndConditionsData.termsHtml} />
          </div>
        </div>
      )}
      <form
        className="overflow-hidden bg-white shadow-sm ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="px-4 py-5 border-b border-gray-200 sm:px-6">
          <Typography as="h3" variant="header-lg">
            <Trans>Agree to Processing Fees</Trans>
          </Typography>
        </div>
        <div className="flex flex-col gap-8 px-4 py-6 sm:p-8">
          {splitConfiguration.overrideFeeSectionContent ? (
            <SanitizeHTML html={splitConfiguration.overrideFeeSectionContent} />
          ) : (
            <ContentCard
              childrenWrapperClassName={cn('flex max-h-96 flex-col overflow-y-auto')}
              type="warn"
            >
              <p>
                <ul>
                  {/* TODO: Add displaying other split configuration settings */}
                  <li>
                    {splitConfiguration.cardPricingModel === 'COST_PLUS' ? (
                      <CostPlusPricingCards
                        splitPercent={splitConfiguration.cardSplitPercentage ?? 0}
                        splitFixed={splitConfiguration.cardSplitFixed ?? 0}
                        locale={locale}
                        currency={splitConfiguration.currency}
                      />
                    ) : (
                      <FlatRatePricingCards
                        splitPercent={splitConfiguration.cardSplitPercentage ?? 0}
                        splitFixed={splitConfiguration.cardSplitFixed ?? 0}
                        locale={locale}
                        currency={splitConfiguration.currency}
                      />
                    )}
                  </li>
                  <br />

                  {(!!splitConfiguration.achSplitFixed ||
                    !!splitConfiguration.achSplitPercentage) && (
                    <li className="mt-3">
                      <Trans>
                        <b>ACH Processing Cost</b>
                        <br />
                        <br />A{' '}
                        {splitConfiguration.achSplitPercentage
                          ? `${formatAmountWithoutCurrency(
                              splitConfiguration.achSplitPercentage,
                              locale,
                              splitConfiguration.currency
                            )}% processing fee `
                          : ''}
                        {splitConfiguration.achSplitFixed
                          ? `fixed ${formatAmount(
                              splitConfiguration.achSplitFixed,
                              locale,
                              splitConfiguration.currency
                            )} fee per transaction `
                          : ''}{' '}
                        will be applied to all ACH transactions.
                      </Trans>
                    </li>
                  )}

                  {!!splitConfiguration.interacSplitFixed && (
                    <li className="mt-6">
                      <Trans>
                        <b>Interac Processing Cost</b>
                        <br />
                        <br />A{' '}
                        {formatAmount(
                          splitConfiguration.interacSplitFixed,
                          locale,
                          splitConfiguration.currency
                        )}{' '}
                        processing fee will be applied to all Interac transactions.
                      </Trans>
                    </li>
                  )}
                  {(!!splitConfiguration.amexSplitFixed ||
                    !!splitConfiguration.amexSplitPercentage) && (
                    <li className="mt-6">
                      <Trans>
                        <b>Amex Processing Cost</b>
                        <br />
                        <br />A{' '}
                        {splitConfiguration.amexSplitPercentage
                          ? `${formatAmountWithoutCurrency(
                              splitConfiguration.amexSplitPercentage,
                              locale,
                              splitConfiguration.currency
                            )}% processing fee `
                          : ''}
                        {splitConfiguration.amexSplitFixed
                          ? `fixed ${formatAmount(
                              splitConfiguration.amexSplitFixed,
                              locale,
                              splitConfiguration.currency
                            )} fee per transaction `
                          : ''}{' '}
                        processing fee will be applied to all Amex transactions.
                      </Trans>
                    </li>
                  )}
                </ul>
              </p>
            </ContentCard>
          )}
          <div className="flex flex-col gap-2">
            <InputCheckbox {...register('agree')}>
              {termsAndConditionsData.termsHtml ? (
                <Trans>
                  I hereby agree to the terms and conditions and consent to the processing fees as
                  outlined above.
                </Trans>
              ) : (
                <Trans>I hereby agree to the processing fees as outlined above.</Trans>
              )}
            </InputCheckbox>
            <ValidationError isVisible={Boolean(errors.agree)}>
              {errors.agree?.message?.toString()}
            </ValidationError>
          </div>
        </div>
        <div className="flex items-center justify-end p-4 gap-x-6 sm:px-8">
          <ValidationError isVisible={signUpMutation.isError}>
            {signUpMutation?.error?.message}
          </ValidationError>
          <Button
            disabled={signUpMutation.isLoading}
            isLoading={signUpMutation.isLoading}
            className="w-full"
            size="xl"
            type="submit"
          >
            <Trans>I agree</Trans>
          </Button>
        </div>
      </form>
    </div>
  );
};
