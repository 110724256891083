import AdyenCheckout from '@adyen/adyen-web';
import {DropinElementProps} from '@adyen/adyen-web/dist/types/components/Dropin/types';
import UIElement from '@adyen/adyen-web/dist/types/components/UIElement';
import {OnAddressLookupType} from '@adyen/adyen-web/dist/types/components/internal/Address/components/AddressSearch';
import {i18n} from '@lingui/core';
import type {CheckoutCreateAdyenSessionOutput} from '@zentact/api/src/trpc/routers/checkoutRouter';
import {DEFAULT_LOCALE, LocaleCode} from '@zentact/common';

/**
 * Custom Drop-in UI Configuration Styles
 * Payment fields are rendered within an iframe, so we need to pass the styles in the Drop-In configuration.
 * Adyen Documentation: https://docs.adyen.com/payment-methods/cards/custom-card-integration#styling
 */
export const CustomDropInCardStyles = {
  card: {
    base: {
      fontFamily: [
        'Inter var',
        'ui-sans-serif',
        'system-ui',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
        'Helvetica Neue',
        'Arial',
        'Noto Sans',
        'sans-serif',
      ],
      fontWeight: 400,
      fontStyle: 'normal',
      fontSize: '14px',
    },
    error: {},
    placeholder: {},
    validated: {},
  },
};

export type BaseAdyenConfiguration = {
  onReady: () => void;
  // biome-ignore lint/suspicious/noExplicitAny: TODO
  onSelect: (paymentMethod: UIElement<any>) => void;
  // biome-ignore lint/suspicious/noExplicitAny: TODO
  onError: (error: any, component: any) => void;
  paymentMethodsConfiguration: {
    card?: {
      // biome-ignore lint/suspicious/noExplicitAny: TODO
      onSubmit?: (state: any, element: any) => void;
      enableStoreDetails?: boolean;
      storePaymentMethod?: boolean;
    };
    ach?: {
      billingAddressRequired: boolean;
    };
    applepay?: {
      // biome-ignore lint/suspicious/noExplicitAny: TODO
      onClick?: (resolve: any, reject: any) => void;
      buttonType?: 'plain' | 'buy' | 'donate' | 'check-out' | 'book' | 'subscribe';
      buttonColor?: 'black' | 'white' | 'white-with-line';
      requiredBillingContactFields: ApplePayJS.ApplePayContactField[];
    };
    paypal?: {
      cspNonce?: string;
      onClick?: () => void;
    };
  };
  payButtonText?: string;
  removePaymentMethods?: string[];
  locale?: LocaleCode;
};

export type AdyenConfigurationManualPayment = Partial<BaseAdyenConfiguration> & {
  method: 'manual';
  // biome-ignore lint/suspicious/noExplicitAny: TODO
  onSubmit: (state: any, component: any) => void;
};

export type AdyenConfigurationAutomaticPayment = Partial<BaseAdyenConfiguration> & {
  method: 'auto';
  // biome-ignore lint/suspicious/noExplicitAny: TODO
  beforeSubmit: (data: any, component: any, actions: any) => void;
  // biome-ignore lint/suspicious/noExplicitAny: TODO
  onPaymentCompleted: (result: any, component: any) => void;
};

export type AdyenConfiguration =
  | AdyenConfigurationManualPayment
  | AdyenConfigurationAutomaticPayment;

/**
 * This function initializes the Adyen Drop-in component.
 *
 * Adyen Documentation for the Drop-in component:
 * https://docs.adyen.com/online-payments/web-drop-in/optional-configuration
 *
 * Note: We are using the Sessions flow, which uses onSubmit only. Advanced flow uses beforeSubmit & onPaymentSuccess.
 * Docs: https://docs.adyen.com/online-payments/web-components/additional-use-cases
 * https://github.com/Adyen/adyen-web/blob/dd22a6568785ecfd3b08b9b87b94fc0eea489f59/packages/lib/src/components/UIElement.tsx#L60
 *
 * Card Configuration: https://docs.adyen.com/payment-methods/cards/web-drop-in
 * Apple Pay Configuration: https://docs.adyen.com/payment-methods/apple-pay/web-drop-in
 * PayPal Configuration: https://docs.adyen.com/payment-methods/paypal/web-drop-in
 * Note (March 5th, 2023): PayPal is still in it's early testing phase, so it's commented out for now.
 *
 */
export const initAdyenCheckout = async (
  domNode: HTMLElement,
  session: CheckoutCreateAdyenSessionOutput,
  config: AdyenConfiguration & {
    paymentMethodsConfiguration: {card: {onAddressLookup?: OnAddressLookupType}};
  }
) => {
  const {method, onReady, paymentMethodsConfiguration, removePaymentMethods} = config;
  let options = {};
  if (method === 'manual') {
    options = {
      onSelect: config.onSelect,
      onError: config.onError,
      onSubmit: config.onSubmit,
    };
  } else {
    options = {
      onSelect: config.onSelect,
      onError: config.onError,
      beforeSubmit: config.beforeSubmit,
      onPaymentCompleted: config.onPaymentCompleted,
    };
  }

  const checkout = await AdyenCheckout({
    ...options,
    environment: session.adyenConfiguration.environment,
    clientKey: session.adyenConfiguration.clientKey,
    session: {
      id: session.id,
      sessionData: session.sessionData,
    },
    showPayButton: true,
    locale: config.locale || DEFAULT_LOCALE,
    translations: {
      [config.locale || DEFAULT_LOCALE]: {
        ...(config.payButtonText ? {payButton: config.payButtonText} : {}),
        storeDetails: i18n._('I agree to store my payment details and authorize future payments'),
        confirmPreauthorization: i18n._('Add Payment Method'),
      },
    },
    ...(removePaymentMethods ? {removePaymentMethods} : {}),
    paymentMethodsConfiguration: {
      card: {
        hasHolderName: true,
        holderNameRequired: true,
        billingAddressRequired: session.adyenConfiguration.billingAddressMode !== 'NONE',
        billingAddressMode: session.adyenConfiguration.billingAddressMode.toLowerCase(),
        amount: {value: session.checkout.amount, currency: session.checkout.currency},
        styles: CustomDropInCardStyles.card,
        ...paymentMethodsConfiguration?.card,
        data: {
          billingAddress: {
            country: session.checkout.countryCode,
          },
          storePaymentMethod: true,
          enableStoreDetails: true,
        },
      },
      ach: {
        hasHolderName: true,
        holderNameRequired: false,
        billingAddressRequired: false,
        // biome-ignore lint/suspicious/noExplicitAny: TODO
      } as any,
      applepay: {
        amount: {value: session.checkout.amount, currency: session.checkout.currency},
        ...paymentMethodsConfiguration?.applepay,
      },
      // biome-ignore lint/style/useNamingConvention: <explanation>
      directdebit_GB: {
        data: {
          // The field is hidden. Needs to pass the validation
          shopperEmail: 'placeholder@email.com',
        },
      },
      /*
      we'll turn this back on when Adyen tells us it's ready
      paypal: {
        blockPayPalPayLaterButton: true,
        amount: {value: session.checkout.amount, currency: session.checkout.currency},
        ...paymentMethodsConfiguration?.paypal,
      },
      */
    },
  });

  const dropinElementProps: DropinElementProps = {
    instantPaymentTypes: [],
    openFirstPaymentMethod: true,
    onReady: onReady,
  };

  const component = checkout.create('dropin', dropinElementProps).mount(domNode);
  return component;
};
