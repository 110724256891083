import {XMarkIcon} from '@heroicons/react/24/outline';
import {useEffect} from 'react';
import {Button} from '../../../';

type CheckoutIFrameProps = {
  expectedOrigin?: string;
  checkoutUrl: string;
  onClose: () => void;
  onSuccess: () => void;
};

export const CheckoutIFrame = ({
  expectedOrigin,
  checkoutUrl,
  onClose,
  onSuccess,
}: CheckoutIFrameProps) => {
  useEffect(() => {
    const handleMessage = (event: {data: string; origin: string}) => {
      if (!expectedOrigin || event.origin !== expectedOrigin) {
        // Ignore messages from unexpected origins for security reasons
        return;
      }

      if (event.data === 'closeIframe') {
        onClose();
        onSuccess();
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [checkoutUrl]);

  return (
    // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={event => {
        if (event.target === event.currentTarget) {
          onClose();
        }
      }}
    >
      <div className="relative">
        <iframe
          src={checkoutUrl}
          width="400"
          height="600"
          allowFullScreen
          title="Checkout Iframe"
          className="bg-white rounded-lg shadow-xl"
        />
        <Button
          onClick={onClose}
          className="absolute top-0 right-0 w-10 h-10 p-2 text-white rounded-full shadow-none"
          aria-label="Close"
          variant="text-secondary"
        >
          <XMarkIcon className="w-6 h-6" stroke="black" aria-hidden="true" />
        </Button>
      </div>
    </div>
  );
};
